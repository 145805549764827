.MuiButton-contained.GoTo {
  background-color: $bg-header-btn-action;
  color: $color-header-btn-action;

  &:hover {
    background-color: $color-header-create;
    color: $bg-header-btn-action;
  }

  .app-folddelay & {
    &:hover {
      color: $color-header-btn-action;
    }
  }
}
