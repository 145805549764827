.form-checkbox {
  --form-checkbox-size: 20px;
  --form-checkbox-border-radius: 4px;

  position: relative;
  padding-left: calc(var(--form-checkbox-size) + 8px);
  font-size: 16px;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--form-checkbox-size);
    height: var(--form-checkbox-size);
    border: 1px solid $color-brand;
    border-radius: var(--form-checkbox-border-radius);

    .app-folddelay &{
      border-color: $color-green-border;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    padding: var(--form-checkbox-border-radius);
    width: var(--form-checkbox-size);
    height: var(--form-checkbox-size);
    opacity: 0;
    transition: all var(--animation-simple-fade);
    color: $color-white;
    background: var(--color-brand-light);
    border-radius: var(--form-checkbox-border-radius);
  }
}

.form-checkbox__input {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  cursor: pointer;
  opacity: 0;

  &:checked {
    + svg {
      opacity: 1;
    }
  }
}
