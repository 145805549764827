.cookies-banner {
  --container-padding: 16px;

  display: flex;
  position: fixed;
  z-index: 1000000;
  bottom: 0;
  left: 0;
  width: calc(100% - (var(--container-padding) * 2));
  max-width: 400px;
  max-height: calc(100% - var(--container-padding));
  overflow-y: auto;
  transform: translateY(calc(100% + 1px));
  color: $color-white;
  background-color: $bg-footer;
  background-image: linear-gradient(45deg, rgba($bg-footer, 1) 0%, rgba($color-black, 1) 100%);
  border: 2px solid $color-brand;
  border-radius: 40px;
  margin-left: var(--container-padding);
  margin-bottom: calc(var(--container-padding) / 2);

  .app-folddelay &{
    border-color: $color-green-border;
  }

  &.cookies-banner--show {
    transform: translateY(0);
    transition: transform 0.5s ease-out;
  }
}

.cookies-banner__page-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: calc(var(--container-padding) * 2);
}

.cookies-banner__content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h5 {
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.cookies-banner__links {
  a {
    display: inline-block;
    text-align: left;

    &:not(:first-child) {
      margin-left: 1em;
    }
  }
}

.cookies-banner__btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  .cookies-banner__btn {
    color: $color-white;
  }

  .MuiButton-outlined {
    border-radius: 40px;
    border-color: $color-brand;

    .app-folddelay &{
      border-color: $color-green-border;
    }

    &:hover {
      background-color: $color-brand;

      .app-folddelay &{
        background-color: $color-green-border;
        color: $color-black;
      }
    }
  }

  .MuiButton-text {
    &:hover {
      color: $color-brand;

      .app-folddelay &{
        color: $color-green-border;
      }
    }
  }
}

.cookies-banner__btn--more {
  order: 2;
}

.cookies-banner__options {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .form-label {
    font-size: 16px;
  }

  .form-checkbox {
    --form-checkbox-check-size: 18px;
  }
}
