#root {
  height: 100vh;
}

.Viewport {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available; // sass-lint:disable-line no-vendor-prefixes, no-duplicate-properties
  max-width: 100%;
  overflow-x: hidden;

  button.MuiButtonBase-root {
    border-radius: 40px;
  }
}

.Viewport__content {
  position: relative;
  flex: 1 1 auto;
  justify-content: center;
  margin-top: 64px; // header height
  padding-top: 16px;
  padding-bottom: 16px;

  &:not(.About__wrapper) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @include breakpoint(until-desktop) {
    margin-top: 144px; // header height
  }

  &.details__main-wrapper {
    max-width: 2560px;
  }

  .Viewport--simple & {
    align-items: flex-start;
  }
}

.Viewport__content-username {
  margin: 0 auto;
  color: $color-brand;
  font-size: 24px;
}

.Viewport__content-bodyText p {
  margin: 10px auto 20px;

  @include breakpoint(tablet) {
    margin: 10px auto 40px;
  }
}
